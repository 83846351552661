<template>
  <div class="page_bg">
    <div class="back_top_name flex-r-sb-c" style=" padding: 0.32rem;background: #ffffff;font-size: 0.48rem;">
      <van-icon name="arrow-left" @click="backPath" />
      <div class="top_name">我的收藏</div>
      <div class="top_icon" />
    </div>
    <div class="cart_company" style="background: rgba(0,0,0,0);margin-left: 0rem;min-height:7rem">
      <div class="company_pro_list"
           style="background: rgba(0,0,0,0);padding-left: 0.3rem;width: 100%;border-radius: 0.2rem;"
      >
        <van-checkbox-group ref="cartCheck" v-model="result" class="checkboxGroup" style="background: rgba(0,0,0,0);">
          <div v-for="(pro_item,pro_index) in collectionList" :key="pro_index" class="flex-r-s-c pro_item_favor"
               style="background: #fff;border-bottom: 1px solid #f3f3f3;"
          >
            <van-checkbox v-model="pro_item.checked" :name="pro_index" checked-color="#0767AB" style="margin:0 0.2rem"
                          @click="itemChange($event,pro_item)"
            />
            <proCard07 :info="pro_item" />
          </div>
        </van-checkbox-group>
        <van-empty v-if="collectionList.length === 0 " class="custom-image"
                   image="https://oss.xiaoyi120.com/shop2.0/my/favor_empty_01.png"
                   description="您还没有收藏的商品..."
        >
          <van-button round class="bottom-button" style="width:3.5rem" color="#0767AB"
                      @click="$router.push('/home/index')"
          >去逛逛</van-button>
        </van-empty>
      </div>
    </div>
    <br><br><br><br>
    <!-- 提交栏 -->
    <div v-if="collectionList.length>0" class="favor_sub pro_del_bar">
      <div class="cart_sub_menu  flex-r-sb-c">
        <div />
        <div class="cart_sub_check">
          <van-checkbox v-model="checkedAll" checked-color="#0767AB" style="font-size:0.32rem" @click="checkAll">全选
          </van-checkbox>
        </div>
        <div class="cart_sub_btn right_btn flex-r-s-s">
          <div class="right_btn_cart" @click="checkPro(1)">取消收藏</div>
          <div class="right_btn_pay" @click="checkPro(2)">加入购物车</div>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import '../cart/index.scss'
import proCard07 from '@/components/pro_card_07'
import { shoppingCart_addUnion } from '@/api/cart'
import { user_collection_deleteAll } from '@/api/pro'
import { collection_list } from '@/api/user'
export default {
  name: 'Cart',
  components: { proCard07 },
  data() {
    return {
      collectionList: [],
      result: [],
      checkedAll: false,
      showProp: false
    }
  },
  created() {
    this.getCartList()
    this.$utils.cartNum(this.$store)
  },
  methods: {
    backPath() { window.history.back() },
    getCartList() {
      collection_list({ page: 1, limit: 9999 }).then(res => {
        this.collectionList = res.data.list
        for (const i in this.collectionList) {
          this.$set(this.collectionList[i], 'checked', false)
        }
        this.$forceUpdate()
      })
    },
    // 单个商品选择
    itemChange(val, item) {
      item.checked = !item.checked
      console.log(item.checked)
      if (this.result.length === this.collectionList.length) { this.checkedAll = true } else { this.checkedAll = false }
    },
    // 全选
    checkAll() {
      this.$refs.cartCheck.toggleAll(this.checkedAll)
      for (const i in this.collectionList) {
        this.$set(this.collectionList[i], 'checked', this.checkedAll)
      }
    },
    // 统计所选商品 == > 进行操作 1：删除 2：加入购物车
    checkPro(type) {
      // 整合数据
      var parms1 = { companyId: '1000', products: [] }
      for (const i in this.collectionList) {
        if (this.collectionList[i].checked) {
          const pro1 = this.collectionList[i].mallProductId
          const pro2 = { productId: this.collectionList[i].mallProductId, proNumber: this.collectionList[i].minQuantity }
          parms1.products.push(pro1)
          if (type === 2) { this.addCart(pro2) }
        }
      }
      if (type === 1) { this.deleteAll(parms1) }
      this.$forceUpdate()
    },
    // 取消所选
    deleteAll(info) {
      user_collection_deleteAll({ productIds: info.products }).then(res => {
        this.collectionList = []
        this.getCartList()
        this.checkedAll = false
        this.checkAll()
        this.$toast({ message: '已取消收藏', duration: 2 * 1000 })
      })
    },
    // 加入购物车
    addCart(info) {
      shoppingCart_addUnion(info).then(res => {
        this.checkedAll = false
        this.checkAll()
        this.$toast({ message: '已加入购物车', duration: 2 * 1000 })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.favor_sub {
  width: 100%;
  position: fixed;
  height: 1.5rem;
  background: #ffffff;
  bottom: 0;
  padding-top: 0.4rem;
}
.right_btn {
  font-size: 0.37rem;
  line-height: 1.17rem;
  color: #ffffff;
  .right_btn_cart {
    width: 2.8rem;
    height: 1.17rem;
    background: #0767ab;
    border-top-left-radius: 0.59rem;
    border-bottom-left-radius: 0.59rem;
  }
  .right_btn_pay {
    width: 2.8rem;
    height: 1.17rem;
    background: #d1531c;
    border-top-right-radius: 0.59rem;
    border-bottom-right-radius: 0.59rem;
  }
  .right_btn_out {
    width: 5.6rem;
    height: 1.17rem;
    background: rgb(135, 153, 163);
    opacity: 1;
    border-radius: 0.59rem;
  }
}
.pro_item_favor:first-child {
  border-top-left-radius: 0.27rem;
  border-top-right-radius: 0.27rem;
}
.pro_item_favor:last-child {
  border-bottom-left-radius: 0.27rem;
  border-bottom-right-radius: 0.27rem;
}
</style>
