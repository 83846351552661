<template>
  <div class="product_box">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="1.92rem" :src="myinfo.proIconUrl"
                 @click="(e) => { $router.push('/product/detail?productId=' + myinfo.mallProductId) }"
      />
      <div style="margin-left:0.2rem">
        <div class="pro_name van-multi-ellipsis--l2" style="width:5rem"
             @click="(e) => { $router.push('/product/detail?productId=' + myinfo.mallProductId) }"
        >
          {{ myinfo.proName }}</div>
        <div class="pro_brand_model van-ellipsis" style="width:4rem"
             @click="(e) => { $router.push('/product/detail?productId=' + myinfo.mallProductId) }"
        >
          {{ myinfo.proBrand }} | {{ myinfo.proModel }}</div>
        <!-- <div class="pro_tags">1</div> -->
        <div class="pro_bottom flex-r-sb-c">
          <div class="pro_money">￥<span
            style="font-size: 0.49rem;"
          >{{ myinfo.showPrice === 0? myinfo.proPrice :'详询客服' }}</span></div>
          <div class="pro_btn">
            <van-icon v-if="myinfo.showPrice === 0 && myinfo.flashId === null" name="cart" @click="toCart(myinfo)" />
            <van-icon v-if="myinfo.showPrice === 1" name="service" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shoppingCart_addUnion } from '@/api/cart'
export default {
  props: {
    info: { type: Object, default: () => { } }
  },
  data() {
    return {
      myinfo: this.info
    }
  },
  methods: {
    // 加入购物车
    toCart(item) {
      const parms = { productId: item.mallProductId, proNumber: item.minQuantity }
      console.log(parms)
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
      })
    }
  }
}
</script>
